import { IconProps } from '@/utilities/types/Icon';

const QuoteStart = (props: IconProps) => {
  const { height = 24, width = 24 } = props;

  return (
    <svg
      clipRule="evenodd"
      fill="none"
      fillRule="evenodd"
      height={height}
      viewBox="0 0 24 24"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.355 3.218a1.216 1.216 0 0 1 1.423.024l1.808 1.332c.73.537.641 1.606-.032 2.109-1.629 1.216-3.102 2.97-3.756 4.63-.02.05-.02.149.096.278.117.128.281.202.408.202 2.52 0 4.467 1.892 4.467 4.474 0 2.597-2.192 4.758-5.079 4.758-3.08 0-5.69-2.416-5.69-6.397 0-4.6 2.62-8.762 6.352-11.408l.003-.002Zm.474.678C3.27 6.421.828 10.351.828 14.628c0 3.57 2.284 5.57 4.862 5.57 2.468 0 4.252-1.828 4.252-3.931 0-2.12-1.57-3.646-3.64-3.646-.4 0-.776-.204-1.022-.476-.245-.27-.426-.695-.252-1.136.724-1.836 2.312-3.705 4.031-4.99.282-.21.266-.61.037-.779L7.283 3.905a.388.388 0 0 0-.454-.009Zm12.675-.668a1.215 1.215 0 0 1 1.432.017l.002.002 1.802 1.326c.73.538.64 1.606-.03 2.11-1.633 1.232-3.083 3.014-3.711 4.693-.014.037-.017.121.089.237.106.115.255.18.369.18 2.51 0 4.543 1.882 4.543 4.474 0 2.602-2.274 4.758-5.079 4.758-3.08 0-5.69-2.416-5.69-6.396 0-4.595 2.614-8.753 6.27-11.4l.003-.001Zm.481.673c-3.49 2.527-5.927 6.454-5.927 10.728 0 3.57 2.285 5.569 4.863 5.569 2.396 0 4.251-1.832 4.251-3.931 0-2.11-1.636-3.646-3.715-3.646-.382 0-.742-.191-.978-.448s-.415-.66-.255-1.087c.695-1.858 2.26-3.76 3.989-5.063.282-.213.266-.614.036-.783l-1.808-1.332a.388.388 0 0 0-.456-.007Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default QuoteStart;
