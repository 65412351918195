import styles from './TestimonialCta.module.scss';

import DOMPurify from 'isomorphic-dompurify';
import Image from 'next/image';
import Social from '@/components/molecules/Social';
import Typography from '@/components/atoms/Typography';
import cn from 'classnames';
import { typeAuthor } from '@/utilities/types/Author';
import { AUTHOR } from '@/utilities/constants/Author';
import { useLocale } from '@/utilities/contexts/Locale';
import typeImage from '@/utilities/types/Image';
import QuoteStart from '@/icons/maggie/QuoteStart';
import { Fragment } from 'react';
import Button from '@/components/atoms/Button';

interface TestimonialCtaProps {
  content: {
    author: typeAuthor;
    cta: {
      ctaText?: string;
      ctaUrl?: string;
    };
    image?: typeImage;
    editor?: string;
  };
}

const TestimonialCta = (props: TestimonialCtaProps) => {
  const { content } = props;

  return (
    <section className={cn(styles.root)}>
      {content?.image && content.image?.mediaItemUrl && (
        <div className={styles.cover}>
          <Image
            alt={content.image?.altText}
            height={content.image?.mediaDetails.height || 600}
            src={content.image.mediaItemUrl}
            width={content.image?.mediaDetails.width || 2246}
          />
        </div>
      )}

      {content?.editor && (
        <Typography
          className={styles.testimonial}
          xs={3}
          md={4}
          lg={5}
          family="noto-sans"
        >
          <QuoteStart width={60} height={60} />

          <div
            className={styles.editor}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(content.editor),
            }}
          />

          {content?.author && (
            <div className={styles.author}>
              {content?.author?.name && (
                <Typography xs={3} weight="bold">
                  {content?.author?.name}
                </Typography>
              )}
              {content?.author?.title && (
                <Typography className={styles.title} xs={3}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(content.author.title),
                    }}
                  />
                </Typography>
              )}
            </div>
          )}

          {content?.cta && content?.cta.ctaUrl && (
            <Button
              href={content?.cta.ctaUrl}
              size="large"
              style="primary"
              className={styles.cta}
            >
              {content?.cta.ctaText}
            </Button>
          )}
        </Typography>
      )}
    </section>
  );
};

export default TestimonialCta;
